import {Component, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {CaltrainService} from './services/caltrain.service';
import {GeoService} from './services/geo.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(100%)'}),
        animate('300ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateY(100%)'}))
      ])
    ])
  ]
})
export class AppComponent implements OnChanges {

  times = [];

  doesArriveAtDestinationList: boolean[] = [];

  north_schedule = null;

  south_schedule = null;

  loading = false;

  title = 'Caltrain Smart Schedule';

  origin = '';

  destination = '';

  latitude = 0;

  longitude = 0;

  closestStation = '';

  isChecked = false;

  nextTrainTime: string = null;

  stations = [
    'Gilroy', 'San Martin', 'Morgan Hill', 'Blossom Hill', 'Capitol', 'Tamien',
    'SJ Diridon', 'Santa Clara', 'Lawrence', 'Sunnyvale', 'Mountain View',
    'San Antonio', 'California Ave', 'Palo Alto', 'Menlo Park', 'Atherton',
    'Redwood City', 'San Carlos', 'Belmont', 'Hillsdale', 'Hayward Park',
    'San Mateo', 'Burlingame', 'Broadway', 'Millbrae', 'San Bruno',
    'So. San Francisco', 'Bayshore', '22nd Street',
    'San Francisco'
  ];

  northbound_order = [
    'Tamien', 'San Jose Diridon', 'Santa Clara', 'Lawrence', 'Sunnyvale', 'Mountain View', 'San Antonio', 'California Ave',
    'Palo Alto', 'Menlo Park', 'Redwood City', 'San Carlos', 'Belmont', 'Hillsdale', 'Hayward Park', 'San Mateo', 'Burlingame',
    'Broadway', 'Millbrae', 'San Bruno', 'South San Francisco', 'Bayshore', '22nd Street', 'San Francisco'];

  constructor(private caltrainService: CaltrainService,
              private geoService: GeoService) {
    this.origin = 'Redwood City';
    this.destination = 'San Antonio';
    const that = this;
    this.loading = true;
    caltrainService.getSchedule().subscribe(
        res => {
            console.log('submission successful!');
            // console.log(JSON.stringify(res))
            const bound = that.getBound(that.origin, that.destination);
            let schedulekey = '';
            if (bound === 'north') {
              schedulekey = 'northbound_schedule';
            } else {
              schedulekey = 'southbound_schedule';
            }
            console.log(JSON.stringify(res['northbound_schedule']));
            console.log(JSON.stringify(res['southbound_schedule']));
            console.log(JSON.stringify(res['northbound_order']));
            console.log('TYPE: ' + typeof res['southbound_schedule']);
            console.log('Keys: ' + Object.keys(res[schedulekey]));
            that.northbound_order = res['northbound_order'];
            that.stations = res['northbound_order'];
            console.log(that.stations);
            const filteredTimes = that.getTimes(res[schedulekey][that.origin], res[schedulekey][that.destination]);
            console.log('filtered times');
            // console.log(filteredTimes)
            that.times = filteredTimes;
            that.north_schedule = res['northbound_schedule'];
            that.south_schedule = res['southbound_schedule'];
            that.onDestinationChanged();
            that.loading = false;
        },
        err => {
            console.log('found an error!');
            console.log(err);
            console.log(err.error);
            // that.setError(err.error)
        }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // changes.prop contains the old and the new value...
    console.log('on changes called');
    console.log(changes);
  }

  geoErrorCallback(err) {
    console.log(err);
  }

  getlocation() {
    if (!this.isChecked) {
      return;
    }
    const that = this;
    navigator.geolocation.getCurrentPosition(
      function(pos) {
        that.latitude = pos.coords.latitude;
        that.longitude = pos.coords.longitude;
        let closestStation = '';
        let smallestDistance = 1000000000;
        for (const [station, geo] of Object.entries(that.geoService.locationMap)) {
          const distance = that.geoService.getDistanceFromLatLonInKm(geo.latitude, geo.longitude, that.latitude, that.longitude);
          if (distance < smallestDistance) {
            smallestDistance = distance;
            closestStation = station;
          }
        }
        that.closestStation = closestStation;
        that.origin = closestStation;
        console.log(smallestDistance);
        that.onOriginChanged();
      },
      function(err) {
        console.log(err);
        that.isChecked = false;
      },
      {timeout: 10000}
      );
  }

  onOriginChanged() {
    this.times = null;
    const bound = this.getBound(this.origin, this.destination);
    console.log('bound is ' + bound);
    let schedule = null;
    if (bound === 'north') {
      schedule = this.north_schedule;
    } else {
      schedule = this.south_schedule;
    }
    console.log('schedule is ' + JSON.stringify(schedule[this.origin]));
    this.times = this.getTimes(schedule[this.origin], schedule[this.destination]);
    console.log('times are ' + this.times);
    this.onDestinationChanged();
  }

  onDestinationChanged() {
    if (this.times == null) {
      return;
    }
    const bound = this.getBound(this.origin, this.destination);
    let schedule: any;
    if (bound === 'north') {
      schedule = this.north_schedule;
    } else {
      schedule = this.south_schedule;
    }
    this.times = this.getTimes(schedule[this.origin], schedule[this.destination]);
  }

  getTimes(origin_times: string[], dest_times: string[]) {
    if (origin_times == null) {
      return null;
    }
    console.log('in get times');
    const now = new Date();
    const trainTime = new Date();
    const returnTimes = [];
    console.log('length: ' + origin_times.length);
    for (let i = 0; i < origin_times.length; i++) {
      if (origin_times[i] === 'null' || origin_times[i] == null) {
        continue;
      }
      let hour = +origin_times[i].substring(0, origin_times[i].indexOf(':'));
      if (origin_times[i].includes('pm') && hour !== 12) {
        hour = 12 + hour;
      }
      trainTime.setHours(hour);
      const minutes = +origin_times[i].substring(origin_times[i].indexOf(':') + 1, origin_times[i].indexOf(':') + 3);
      trainTime.setMinutes(minutes);
      console.log('iterating!');
      if (trainTime > now) {
        const desttime = dest_times[i];
        if (!(desttime === 'null' || desttime == null)) {
          returnTimes.push([origin_times[i].replace('+', ''), desttime.replace('+', '')]);
        }
      }
    }
    console.log('return times is ' + returnTimes);
    return returnTimes;
  }

  // returns 'north' if northbound and 'south' if southbound
  getBound(origin, destination) {
    const originIndex = this.northbound_order.indexOf(origin);
    const destIndex = this.northbound_order.indexOf(destination);
    if (originIndex < destIndex) {
      return 'north';
    }
    return 'south';
  }

  getHTMLName(name: string) {

    return name;
  }

  // onclick() {
  //   this.nextTrainTime = null;
  //   console.log("origin")
  //   console.log(this.origin)
  //   console.log("destination")
  //   console.log(this.destination)
  // }
}
