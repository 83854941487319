import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

const URL = "https://us-central1-daring-bit-264300.cloudfunctions.net/caltrain-schedule"

@Injectable({
  providedIn: 'root',
})
export class CaltrainService {

  northbound_schedule: Map<String, String[]> = null

  southbound_schedule: Map<String, String[]> = null

  constructor(private http: HttpClient) {}

  getSchedule() {
    let that = this;

    // we don't have the schedule yet
    console.log("going to make call")
    return this.http.get(URL).pipe(
      map(
        res => {
          return res;
        }
      )
    );
  }
}
