import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {CaltrainService} from './services/caltrain.service';
import { HttpClientModule } from '@angular/common/http';
import { ViewEncapsulation } from '@angular/core';


import {
  MatToolbarModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatSelectModule,
  MatProgressSpinnerModule, MatSlideToggleModule
} from '@angular/material';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {GeoService} from './services/geo.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    MatSlideToggleModule
  ],
  providers: [CaltrainService, GeoService,],
  bootstrap: [AppComponent]
})
export class AppModule { }
