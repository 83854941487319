import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GeoService {

  constructor() {}

  locationMap = {
    'San Francisco': {
      latitude: 37.77655000017461,
      longitude: -122.39470001527397
    },
    '22nd Street': {
      latitude: 37.757545982636444,
      longitude: -122.39295115936183
    },
    'Bayshore': {
      latitude: 37.70873980573696,
      longitude: -122.40157084297543
    },
    'South San Francisco': {
      latitude: 37.65577520061604,
      longitude: -122.40534179689732
    },
    'San Bruno': {
      latitude: 37.62980102866739,
      longitude: -122.41131841158067
    },
    'Millbrae': {
      latitude: 37.599913660521,
      longitude: -122.38678412918101
    },
    'Burlingame': {
      latitude: 37.58002701851157,
      longitude: -122.34508435704569
    },
    'San Mateo': {
      latitude: 37.567856647957406,
      longitude: -122.32366912704391
    },
    'Hayward Park': {
      latitude: 37.55288061221942,
      longitude: -122.30874200851795
    },
    'Hillsdale': {
      latitude: 37.54172685627078,
      longitude: -122.30106256742708
    },
    'Belmont': {
      latitude: 37.52128283628073,
      longitude: -122.27634555195817
    },
    'San Carlos': {
      latitude: 37.50793489830638,
      longitude: -122.26023560163713
    },
    'Redwood City': {
      latitude: 37.48560189528873,
      longitude: -122.23088613148178
    },
    'Menlo Park': {
      latitude: 37.45478061195819,
      longitude: -122.18256514113253
    },
    'Palo Alto': {
      latitude: 37.44386415653255,
      longitude: -122.16520196378241
    },
    'California Ave': {
      latitude: 37.42915493366773,
      longitude: -122.14191090367704
    },
    'San Antonio': {
      latitude: 37.40720610960409,
      longitude: -122.10710731571876
    },
    'Mountain View': {
      latitude: 37.3945403415855,
      longitude: -122.07602809867755
    },
    'Sunnyvale': {
      latitude: 37.37841958707981,
      longitude: -122.03076951746826
    },
    'Lawrence': {
      latitude: 37.37039403553209,
      longitude: -121.99598579608288
    },
    'Santa Clara': {
      latitude: 37.35308175242882,
      longitude: -121.93615487842987
    },
    'College Park': {
      latitude: 37.34268118167545,
      longitude: -121.91551311915352
    },
    'San Jose Diridon': {
      latitude: 37.33066544208525,
      longitude: -121.90235996356849
    },
    'Tamien': {
      latitude: 37.312614769282284,
      longitude: -121.8847281207924
    },
    'Capitol': {
      latitude: 37.283967762598294,
      longitude: -121.84175553262014
    },
    'Blossom Hill': {
      latitude: 37.25239787429567,
      longitude: -121.79747510775388
    },
    'Morgan Hill': {
      latitude: 37.12970845207391,
      longitude: -121.65056707718324
    },
    'San Martin': {
      latitude: 37.0857748501336,
      longitude: -121.61045225575184
    },
    'Gilroy': {
      latitude: 37.00444140332936,
      longitude: -121.56688460092695
    }
  };

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // Distance in km
    return R * c;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
}

